import { useEffect, useState } from 'react';
import { profileCompanySelector } from 'reducers/profile/company.reducer';
import { useTsSelector } from 'store/store.types';


export function useApiGet<F extends (id: number | string) => Promise<unknown>>(
  action: F,
  id: number | string,
  locationValue?: Awaited<ReturnType<F>>,
  options?: {
    isCompanyRelated?: boolean,
  },
): [Awaited<ReturnType<F>> | undefined, boolean] {

  const [item, setItem] = useState<Awaited<ReturnType<F>> | undefined>(locationValue);
  const [loading, setLoading] = useState<boolean>(false);
  const company = useTsSelector(profileCompanySelector);

  const needToReload = options?.isCompanyRelated && company.id;

  useEffect(() => {
    if (locationValue) {
      return;
    }
    setLoading(true);
    action(id)
      .then(result => { setItem(result as Awaited<ReturnType<F>>); })
      .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needToReload]);

  return [item, loading];
}
