import { TFunction } from 'i18next';

export const HttpStatus = {
  OK_200: 200,
  CREATED_201: 201,
  ACCEPTED_202: 202,
  NO_CONTENT_204: 204,
  BAD_REQUEST_400: 400,
  UNAUTHORIZED_401: 401,
  FORBIDDEN_403: 403,
  NOT_FOUND_404: 404,
  METHOD_NOT_ALLOWED_405: 405,
  INTERNAL_SERVER_ERROR_500: 500,
  BAD_GATEWAY_502: 502,
  SERVICE_UNAVAILABLE_503: 503,
  GATEWAY_TIMEOUT_504: 504,
};

const SIDE_BAR_WIDTH = 240;

export const MAX_FILE_SIZE_MB = 3;

export const SOCIAL_LINKS = {
  facebook: 'https://www.facebook.com/glendeal.agro/',
  linkedin: 'https://www.linkedin.com/company/glendeal/',
  instagram: 'https://www.instagram.com/glendeal_com/',
} as const;

export const CONTACTS = {
  phoneUA: {
    full: '+380 73 048 55 45 (6825)',
    line: '6825',
    number: '+380 73 048 55 45',
  },
  phonePL: {
    full: '+48 221643638 (2824)',
    line: '6824',
    number: '+48 221643638',
  },
  supportEmail: {
    email: 'support@glendeal.com',
  },
  contractEmail: {
    email: 'contract@glendeal.com',
  },
  publickEmail: {
    email: 'public@glendeal.com',
  },
} as const;

export const SHOW_CONTACT_VALUES = ['for_all', 'for_my_hands', 'for_two_hands', 'never'] as const;

export enum LotType {
  offer = 'offer',
  request = 'request',
}
export enum TradeStatus {
  open = 'open',
  confirming = 'confirming',
  confirmed = 'confirmed',
  closed = 'closed',
}

const TYPES_MARKET = {
  offer: 'offer',
  request: 'request',
};

const API_ROUTES = {
  activation: {
    post: '/users/activation/',
    resend: '/users/activation/resend/',
    check: '/users/auth/short-link-verification/',
  },
  authMe: '/users/auth/me/',
  businessCard: '/image_data/',
  companies: {
    root: '/companies/',
    details: '/companies/details/',
    companyPositions: '/companies/company_positions/',
    companyTypes: '/companies/company_types/',
    contacts: '/companies/company_contacts/',
    handshake: '/companies/{:target_id}/handshake',
  },
  myCompany: {
    root: '/companies/my_company/',
    info: '/companies/my_company/',
    companyContacts: '/companies/my_company/company_contacts/',
  },
  cultures: '/cultures/',
  dashboard: {
    offer: '/offers/dashboard/',
    request: '/requests/dashboard/',
  },
  favorites: {
    toggle: '/favorites/',
  },
  goods: '/goods/',
  lotOveralls: {
    offer: '/offers/overalls//',
    request: '/requests/overalls/',
  },
  markets: '/markets',
  notifications: '/notifications/',
  offers: '/offers/',
  offersTrades: '/offers/trades/',
  offersFavorites: '/offers/favorites/',
  readNotifications: '/notifications/read/',
  requests: '/requests/',
  lots: {
    offer: {
      remove: '/offers/{:id}/delete/',
      archive: '/offers/{:id}/archive/',
    },
    request: {
      remove: '/requests/{:id}/delete/',
      archive: '/requests/{:id}/archive/',
    },
  },
  requestsFavorites: '/requests/favorites/',
  requestsTrades: '/requests/trades/',
  reviews:'/reviews/',
  review_categories:'/review_categories/',
  settings: {
    addresses: '/addresses/',
  },
  shippingDocuments: '/shipping_documents/',
  trades: {
    root: '/trades/',
    close: '/trades/{:id}/close/',
    others: '/trades/others/',
    infoBadges: '/trades/info_badges/',
  },
  userDashboard: {
    request: '/requests/user_dashboard/',
    offer: '/offers/user_dashboard/',
  },
  users: {
    root: '/users/',
    tokens: {
      phoneVerify: '/users/tokens/phone-verify/',
    },
    registration_flow: {
      business_card: '/users/registration_flow/business_card/',
    },
    new_registration: '/users/new_registration/',
  },
  userRequests: '/user/requests/',
  userOffers: 'user/offers/',
};

const REQUESTS_NAMES = (_t: TFunction): string[] => [_t('запрос'), _t('запроса'), _t('запросов')];
const OFFERS_NAMES = (_t: TFunction): string[] => [_t('лот'), _t('лота'), _t('лотов')];

export enum FormatEnum {
  adaptive = 'adaptive',
  adaptive_01 = 'HH:mm A',
  adaptive_02 = 'YYYY-MM-DD',
  dateOfManufacture = 'YYYY',
  main = 'YYYY/MM/DD',
  dayMonth = 'MM/DD',
  mainForBack = 'YYYY-MM-DD',
  time24 = 'HH:mm',
  simpleDatetime = 'MM/DD HH:mm',
  full = 'YYYY/MM/DD HH:mm:ss',
  fromNow = 'fromNow',
}

export const FORMAT_CYCLE = [FormatEnum.adaptive, FormatEnum.fromNow, FormatEnum.full, FormatEnum.main, FormatEnum.simpleDatetime];

const STATUS_CODES = {
  ok: 200,
  alredyReported: 208,
  badRequest: 400,
  forbidden: 403,
  notFound: 404,
  conflict: 409,
  emptyPage: 410,
  precondition: 428,
};


const STEP_ACTIONS = {
  next: 'next',
  prev: 'prev',
};

const NOTIFICATIONS_WIDGET = {
  mobile: 4,
  desktop: 5,
};

export enum UserStatusEnum {
  User = 'user',
  Admin = 'admin',
  UserWithoutCompany = 'newAdmin',
}

const VALIDATION_VALUES = {
  oneHundred: 100,
  twoHundred: 200,
  tenThousand: 10000,
  maximumIntegerValue: 32767,
  lessMillion: 999999,
};


export {
  SIDE_BAR_WIDTH,
  TYPES_MARKET,
  API_ROUTES,
  REQUESTS_NAMES,
  OFFERS_NAMES,
  STATUS_CODES,
  NOTIFICATIONS_WIDGET,
  STEP_ACTIONS,
  VALIDATION_VALUES,
};
