import { SCOPE_LOT } from 'i18n/_single/Lot';

export const SCOPE_FORMS = 'forms';
export const SCOPE_FORM_MY_COMPANY_SCOPE = 'FormMyCompany';
export const SCOPE_FORM_LOT = 'FormLot';


export const ScopeFormsName = (name: keyof typeof uk['Fields']): string[] => {
  return [SCOPE_FORMS, 'Fields', name];
};

export const uk = {
  errors: require('./errors').uk,
  Fields: {
    email: {
      label: 'Електронна пошта',
      placeholder: 'Email',
    },
    contact_name: {
      label: 'Контактна особа',
      placeholder: 'Ім\'я',
    },
    phone_number: {
      label: 'Телефон',
      placeholder: '+380 _______',
      tooltips: {
        verified: 'Цей номер веріфіцировано',
        notVerified: 'Натисніть для початку процесу верифікації',
      },
      messages: {
        sms_send_success: 'Повідомлення успішно відправлено',
        sms_send_409: 'Для подальшої верифікації, зверніться, будь ласка, до служби підтримки',
        validate_success: 'Номер успішно підтверджено',
      },
      errors: {
        sms_send_409: 'Перевіщен ліміт спроб',
      },
      confirmation: {
        enter_pincode: 'Введіть пінкод',
        your_phone_number: 'Ваш номер',
        get_pincode: 'Отримати код',
      },
    },
    language: {
      label: 'Мова',
    },
    country: {
      title: 'Країна розміщення',
      label: 'Країна',
      placeholder: 'Країна розміщення',
    },
    country_id: {
      title: 'Країна розміщення',
      label: 'Країна',
      placeholder: 'Країна розміщення',
    },
    company_id: {
      label: 'Компанія',
      placeholder: 'Компанія',
    },
    company_types: {
      title: 'Тип діяльності',
      label: 'Типи',
      placeholder: 'Тип компанії',
    },
    countries: {
      title: 'З якими ринками працюєте',
      label: 'Ринок',
      placeholder_c0: 'Ринок',
      placeholder_one: 'Вибран {{count}} ринок',
      placeholder_few: 'Вибрано {{count}} ринку',
      placeholder_many: 'Вибрано {{count}} ринків',
    },
    cultures: {
      title: 'Продукт',
      label: 'Виберіть продукт',
      placeholder: 'Жовтий горох',
    },
    files: {
      buttonAdd: 'Додати файл',
      buttonFull: 'Додати файл',
      hint: 'Формат документів (до {{maxFiles}} штук, до {{MAX_FILE_SIZE_MB}} MB) PDF, DOC,...',
      errors: {
        maxSize: 'Деякі файли ({{count}} шт.) перевищують максимальний розмір ({{MAX_FILE_SIZE_MB}} MB)',
      },
    },
    first_name: {
      label: 'Ім\'я',
      placeholder: 'Ім\'я',
    },
    is_organic: {
      label: 'Органічний продукт',
      tooltip: 'Органічний продукт',
    },
    last_name: {
      label: 'Прізвище',
      placeholder: 'Прізвище',
    },
    location_id: {
      label: 'Адреса',
      placeholder: 'Адреса місцезнаходження',
    },
    logo: {
      label: 'Завантажте лого для профілю',
      buttonChoose: 'Виберіть лого',
      hint: 'Формат логотипу JPG, PNG, WEBP',
    },
    name: {
      label: 'Найменування',
      placeholder: 'Найменування',
    },
    port_options: {
      label: 'Порт / Місто',
      placeholder: 'Порт / Місто',
    },
    ports: {
      title: 'Ваші призначення',
      label: 'Призначення',
      placeholder: 'Порт / Місто',
      placeholder_c0: 'Порт / Місто',
      placeholder_one: 'Вибрано {{count}} призначення',
      placeholder_few: 'Вибрано {{count}} призначення',
      placeholder_many: 'Вибрано {{count}} призначень',
    },
    shipments: {
      title: 'Типи відвантажень',
      label: 'Тип',
      placeholder_c0: 'Тип',
      placeholder_one: 'Вибран {{count}} тип',
      placeholder_few: 'Вибрано {{count}} типи',
      placeholder_many: 'Вибрано {{count}} типів',
    },
    address: {
      label: 'Адреса',
      placeholder: 'Адреса компанії',
    },
    local_name: {
      label: 'Локальна назва',
      placeholder: 'Локальна назва компанії',
    },
    description: {
      label: 'Опис',
      placeholder: 'Опис',
    },
    site: {
      label: 'Сайт',
      placeholder: 'Сайт компанії',
    },
  },
  Buttons: {
    FormCompany: {
      creationComplete: 'Надіслати на верифікацію',
    },
    remove_all: 'Видалити всі',
  },
  [SCOPE_FORM_LOT]: {
    price: {
      placeholder: `$t(${SCOPE_LOT}$zeroPrice)`,
    },
    parameters: {
      tooltips: {
        not_default_value: 'Цей параметр має значення, відмінне від стандартного {{defaultValue}}{{units}}',
        not_empty_as_default_value: 'Цей параметр не має стандартного значення',
      },
    },
  },
};

export const en = {
  errors: require('./errors').en,
  Fields: {
    email: {
      label: 'Email',
      placeholder: 'Email',
    },
    contact_name: {
      label: 'Contact person',
      placeholder: 'Name',
    },
    phone_number: {
      label: 'Phone',
      placeholder: '+380 _______',
      tooltips: {
        verified: 'This number has been verified',
        notVerified: 'Click to start the verification process',
      },
      messages: {
        sms_send_success: 'The message was sent successfully',
        sms_send_409: 'For further verification, please contact the support team',
        validate_success: 'The number has been successfully verified',
      },
      errors: {
        sms_send_409: 'Attempt limit exceeded',
      },
      confirmation: {
        enter_pincode: 'Enter the pin code',
        your_phone_number: 'Your phone number',
        get_pincode: 'Get the code',
      },
    },
    language: {
      label: 'Language',
    },
    country: {
      title: 'Host country',
      label: 'Country',
      placeholder: 'Host country',
    },
    country_id: {
      title: 'Host country',
      label: 'Country',
      placeholder: 'Host country',
    },
    company_id: {
      label: 'Company',
      placeholder: 'Company',
    },
    company_types: {
      title: 'Type of activity',
      label: 'Types',
      placeholder: 'Company type',
    },
    countries: {
      title: 'Markets you are working at',
      label: 'Market',
      placeholder_c0: 'Market',
      placeholder_one: 'Selected {{count}} country',
      placeholder_other: 'Selected {{count}} countries',
    },
    cultures: {
      title: 'Product',
      label: 'Choose product',
      placeholder: 'Yellow peas',
    },
    files: {
      buttonAdd: 'Add file',
      buttonFull: 'Add file',
      hint: 'Docs format (up {{maxFiles}} items, up {{MAX_FILE_SIZE_MB}} MB) PDF, DOC,...',
      errors: {
        maxSize: 'Some files ({{count}} pcs.) exceed the maximum size ({{MAX_FILE_SIZE_MB}} MB)',
      },
    },
    first_name: {
      label: 'First name',
      placeholder: 'First name',
    },
    is_organic: {
      label: 'Organic product',
      tooltip: 'Organic product',
    },
    last_name: {
      label: 'Last name',
      placeholder: 'Last name',
    },
    location_id: {
      label: 'Address',
      placeholder: 'Location address',
    },
    logo: {
      label: 'Upload a logo for your profile',
      buttonChoose: 'Select a logo',
      hint: 'Logo format JPG, PNG, WEBP',
    },
    name: {
      label: 'Name',
      placeholder: 'Name',
    },
    port_options: {
      label: 'Port / City',
      placeholder: 'Port / City',
    },
    ports: {
      title: 'Your destinations',
      label: 'Destination',
      placeholder: 'Port / City',
      placeholder_c0: 'Port / City',
      placeholder_one: 'Selected {{count}} destination',
      placeholder_other: 'Selected {{count}} destinations',
    },
    shipments: {
      title: 'Shipment types',
      label: 'Type',
      placeholder_c0: 'Type',
      placeholder_one: 'Selected {{count}} type',
      placeholder_other: 'Selected {{count}} types',
    },
    address: {
      label: 'Address',
      placeholder: 'Company address',
    },
    local_name: {
      label: 'Local name',
      placeholder: 'Local company name',
    },
    description: {
      label: 'Description',
      placeholder: 'Description',
    },
    site: {
      label: 'Site',
      placeholder: 'Company site',
    },
  },
  Buttons: {
    FormCompany: {
      creationComplete: 'Send to verification',
    },
    remove_all: 'Remove all',
  },
  [SCOPE_FORM_LOT]: {
    price: {
      placeholder: `$t(${SCOPE_LOT}$zeroPrice)`,
    },
    parameters: {
      tooltips: {
        not_default_value: 'This parameter has a value other than the default {{defaultValue}}{{units}}',
        not_empty_as_default_value: 'This parameter has no default value',
      },
    },
  },
};

export const ru: typeof uk = {
  errors: require('./errors').ru,
  Fields: {
    email: {
      label: 'Email',
      placeholder: 'Email',
    },
    contact_name: {
      label: 'Контактное лицо',
      placeholder: 'Имя',
    },
    phone_number: {
      label: 'Телефон',
      placeholder: '+380 _______',
      tooltips: {
        verified: 'Этот номер верифицирован',
        notVerified: 'Нажмите для начала процесса верификации',
      },
      messages: {
        sms_send_success: 'Сообщение успешно отправлено',
        sms_send_409: 'Для дальнейшей верификации, обратитесь, пожалуйста, в службу поддержки',
        validate_success: 'Номер успешно подтвержден',
      },
      errors: {
        sms_send_409: 'Истек лимит попыток',
      },
      confirmation: {
        enter_pincode: 'Введите пинкод',
        your_phone_number: 'Ваш номер',
        get_pincode: 'Получить код',
      },
    },
    language: {
      label: 'Язык',
    },
    country: {
      title: 'Страна размещения',
      label: 'Страна',
      placeholder: 'Страна размещения',
    },
    country_id: {
      title: 'Страна размещения',
      label: 'Страна',
      placeholder: 'Страна размещения',
    },
    company_id: {
      label: 'Компания',
      placeholder: 'Компания',
    },
    company_types: {
      title: 'Тип деятельности',
      label: 'Типы',
      placeholder: 'Тип компании',
    },
    countries: {
      title: 'С какими рынками работаете',
      label: 'Рынок',
      placeholder_c0: 'Рынок',
      placeholder_one: 'Выбран {{count}} рынок',
      placeholder_few: 'Выбрано {{count}} рынка',
      placeholder_many: 'Выбрано {{count}} рынков',
    },
    cultures: {
      title: 'Продукт',
      label: 'Выберите продукт',
      placeholder: 'Желтый горох',
    },
    files: {
      buttonAdd: 'Добавить файл',
      buttonFull: 'Додати файл',
      hint: 'Формат документов (до {{maxFiles}} штук, до {{MAX_FILE_SIZE_MB}} MB) PDF, DOC,...',
      errors: {
        maxSize: 'Некоторые файлы ({{count}} шт.) превышают максильмальный размер ({{MAX_FILE_SIZE_MB}} MB)',
      },
    },
    first_name: {
      label: 'Имя',
      placeholder: 'Имя',
    },
    is_organic: {
      label: 'Органический продукт',
      tooltip: 'Органический продукт',
    },
    last_name: {
      label: 'Фамилия',
      placeholder: 'Фамилия',
    },
    location_id: {
      label: 'Адрес',
      placeholder: 'Адрес местонахождения',
    },
    logo: {
      label: 'Загрузите лого для профиля',
      buttonChoose: 'Выберите лого',
      hint: 'Формат лого JPG, PNG, WEBP',
    },
    name: {
      label: 'Наименование',
      placeholder: 'Наименование',
    },
    port_options: {
      label: 'Порт / Город',
      placeholder: 'Порт / Город',
    },
    ports: {
      title: 'Ваши напрвления',
      label: 'Направление',
      placeholder: 'Порт/ Город',
      placeholder_c0: 'Порт/ Город',
      placeholder_one: 'Выбрано {{count}} направление',
      placeholder_few: 'Выбрано {{count}} направления',
      placeholder_many: 'Выбрано {{count}} направлений',
    },
    shipments: {
      title: 'Виды отгрузок',
      label: 'Тип',
      placeholder_c0: 'Тип',
      placeholder_one: 'Выбран {{count}} тип',
      placeholder_few: 'Выбрано {{count}} типа',
      placeholder_many: 'Выбрано {{count}} типов',
    },
    address: {
      label: 'Адрес',
      placeholder: 'Адрес компании',
    },
    local_name: {
      label: 'Локальное название',
      placeholder: 'Локальное название компании',
    },
    description: {
      label: 'Описание',
      placeholder: 'Описание',
    },
    site: {
      label: 'Сайт',
      placeholder: 'Сайт компании',
    },
  },
  Buttons: {
    FormCompany: {
      creationComplete: 'Отправить на верификацию',
    },
    remove_all: 'Удалить все',
  },
  [SCOPE_FORM_LOT]: {
    price: {
      placeholder: `$t(${SCOPE_LOT}$zeroPrice)`,
    },
    parameters: {
      tooltips: {
        not_default_value: 'Данный параметр имеет значение отличное от стандартного {{defaultValue}}{{units}}',
        not_empty_as_default_value: 'Данный параметр не имеет стандартного значения',
      },
    },
  },
};

export const bg: typeof en = {
  errors: require('./errors').bg,

  Fields: {
    email: {
      label: 'Имейл',
      placeholder: 'Email',
    },
    contact_name: {
      label: 'Лице за контакт',
      placeholder: 'Име',
    },
    phone_number: {
      label: 'Телефон',
      placeholder: '+380 _______',
      tooltips: {
        verified: 'Този номер е верифициран',
        notVerified: 'Натиснете за започване на процеса на верификация',
      },
      messages: {
        sms_send_success: 'Съобщението беше успешно изпратено',
        sms_send_409:
          'За по-нататъшна верификация, моля, свържете се с поддръжката',
        validate_success: 'Номерът беше успешно потвърден',
      },
      errors: {
        sms_send_409: 'Изтече лимитът на опитите',
      },
      confirmation: {
        enter_pincode: 'Въведете пинкод',
        your_phone_number: 'Вашият номер',
        get_pincode: 'Получете код',
      },
    },
    language: {
      label: 'Език',
    },
    country: {
      title: 'Държава на разположение',
      label: 'Държава',
      placeholder: 'Държава на разположение',
    },
    country_id: {
      title: 'Държава на разположение',
      label: 'Държава',
      placeholder: 'Държава на разположение',
    },
    company_id: {
      label: 'Компания',
      placeholder: 'Компания',
    },
    company_types: {
      title: 'Вид дейност',
      label: 'Види',
      placeholder: 'Вид фирма',
    },
    countries: {
      title: 'С кои пазари работите',
      label: 'Пазар',
      placeholder_c0: 'Пазар',
      placeholder_one: 'Избран {{count}} пазар',
      placeholder_other: 'Избрани {{count}} пазари',
    },
    cultures: {
      title: 'Продукт',
      label: 'Изберете продукт',
      placeholder: 'Жълт грах',
    },
    files: {
      buttonAdd: 'Добавяне на файл',
      buttonFull: 'Добавяне на файл',
      hint: 'Формат на документа (до {{maxFiles}} броя документи, до {{MAX_FILE_SIZE_MB}} MB) PDF, DOC,...',
      errors: {
        maxSize: 'Някои файлове ({{count}} бр.) надвишават максималния размер ({{MAX_FILE_SIZE_MB}} MB)',
      },
    },
    first_name: {
      label: 'Име',
      placeholder: 'Име',
    },
    is_organic: {
      label: 'Органичен продукт',
      tooltip: 'Органичен продукт',
    },
    last_name: {
      label: 'Фамилия',
      placeholder: 'Фамилия',
    },
    location_id: {
      label: 'Адрес',
      placeholder: 'Адрес на местоположението',
    },
    logo: {
      label: 'Качване на лого на профил',
      buttonChoose: 'Избор на лого',
      hint: 'Формат на логото JPG, PNG, WEBP',
    },
    name: {
      label: 'Име',
      placeholder: 'Име',
    },
    port_options: {
      label: 'Пристанище / Град',
      placeholder: 'Пристанище / Град',
    },
    ports: {
      title: 'Вашите направления',
      label: 'Направление',
      placeholder: 'Пристанище / Град',
      placeholder_c0: 'Пристанище / Град',
      placeholder_one: 'Избрано {{count}} направление',
      placeholder_other: 'Избрани {{count}} направления',
    },
    shipments: {
      title: 'Видове товари',
      label: 'Тип',
      placeholder_c0: 'Тип',
      placeholder_one: 'Избран {{count}} тип',
      placeholder_other: 'Избрани {{count}} типа',
    },
    address: {
      label: 'Адрес',
      placeholder: 'Адрес на компанията',
    },
    local_name: {
      label: 'Местно име',
      placeholder: 'Местно име на компанията',
    },
    description: {
      label: 'Описание',
      placeholder: 'Описание',
    },
    site: {
      label: 'Сайт',
      placeholder: 'фирмен уебсайт',
    },
  },
  Buttons: {
    FormCompany: {
      creationComplete: 'Изпрати на верификация',
    },
    remove_all: 'Изтрий всичко',
  },
  [SCOPE_FORM_LOT]: {
    price: {
      placeholder: `$t(${SCOPE_LOT}$zeroPrice)`,
    },
    parameters: {
      tooltips: {
        not_default_value: 'Този параметър има стойност, различна от стандартната {{defaultValue}}{{units}}',
        not_empty_as_default_value: 'Този параметър няма стандартна стойност',
      },
    },
  },
};
