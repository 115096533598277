import { useEffect, useState } from 'react';
import { toApiQuery } from 'api/utils';
import { AnyTODO } from 'core/interfaces';
import { useTsSelector } from 'store/store.types';
import { profileCompanySelector } from 'reducers/profile/company.reducer';
import { IApiListResponseNew } from 'reducers/slices/interfaces';

type Func<T = AnyTODO, A = AnyTODO> = (arg?: A) => Promise<IApiListResponseNew<T>>;

export function useApiListWithParams<F extends Func>(
  action: F,
  params?: Parameters<F>[0],
  options?: {
    isCompanyRelated?: boolean,
  },
): [Awaited<ReturnType<F>> | undefined, boolean] {

  const [items, setItems] = useState<Awaited<ReturnType<F>>>();
  const [loading, setLoading] = useState<boolean>(true);
  const company = useTsSelector(profileCompanySelector);

  const query = params && toApiQuery(params);
  const needToReload = options?.isCompanyRelated && company.id;

  useEffect(() => {
    setLoading(true);
    action(params)
      .then(result => { setItems(result as Awaited<ReturnType<F>>); })
      .finally(() => setLoading(false));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query, needToReload]);

  return [items, loading];
}
