import { LotType } from './general';


export const ROUTES = {
  activation: '/activation/:activation_token/',
  chat: '/chat/',
  categories: '/categories/:categoryChurl/',
  cultures: '/cultures/:cultureChurl/',
  lot: `/proposals/:type(${Object.keys(LotType).join('|')})/:id(\\d+)/`,
  editLot: `/proposals/:type(${Object.keys(LotType).join('|')})/:id(\\d+)/edit/`,
  createLot: `/proposals/:type(${Object.keys(LotType).join('|')})/create/`,
  // tradeLot: `/proposals/:type(${Object.keys(LotType).join('|')})/:id(\\d+)/trade/`,
  cloneLot: `/proposals/:type(${Object.keys(LotType).join('|')})/:id(\\d+)/clone/`,
  cabinet: {
    root: '/cabinet/',
    deals: '/cabinet/deals/',
    sends: '/cabinet/sends/',
    my_lots: '/cabinet/my_lots/',
    my_trades: '/cabinet/my_trades/',
    my_goods: {
      root: '/cabinet/my_goods/',
      create: '/cabinet/my_goods/create/',
      edit: '/cabinet/my_goods/edit/:id',
      show: '/cabinet/my_goods/show/:id',
    },
    my_company: {
      root: '/cabinet/my_company/',
      contacts: {
        root: '/cabinet/my_company/contacts/',
        create: '/cabinet/my_company/contacts/create/',
        edit: '/cabinet/my_company/contacts/edit/:id',
        show: '/cabinet/my_company/contacts/show/:id',
      },
      reviews: {
        root: '/cabinet/my_company/reviews/',
        my: '/cabinet/my_company/reviews/my',
      },
      history: '/cabinet/my_company/history/',
    },
    favorite_companies: '/cabinet/favorite_companies/',
  },
  companies: {
    root: '/companies/',
    support_manager: '/companies/support_manager/',
    show: {
      root: '/companies/:id(\\d+)/',
      goods: '/companies/:id(\\d+)/goods/',
      contacts: '/companies/:id(\\d+)/contacts/',
      reviews: '/companies/:id(\\d+)/reviews/',
      reviews_create: '/companies/:id(\\d+)/reviews/create/',
      history: '/companies/:id(\\d+)/history/',
    },
  },
  createFromFavorites: '/proposals/offer/create/',
  createFromMarket: '/proposals/offer/create/',
  createFromMyProposals: '/proposals/offer/create/',
  createFromRequests: '/proposals/offer/create/',
  createFromTrades: '/proposals/offer/create/',
  dashboard: '/dashboard/',
  emailConfirmationInfo: '/email-confirmation-info/',
  emptyPage: '/market/empty-page/',
  favorites: '/favorites/',
  home: '/',
  landingPageLayout: {
    root: '/',
    about: '/about/',
    contacts: '/contacts/',
    farmers: '/farmers/',
    brokers: '/brokers/',
    customers: '/buyers/',
  },
  howItWorks: '/how-it-works/',
  logout: '/logout/',
  invite: '/invite/:token/',
  market: '/market/',
  marketCategory: '/market/:categoryChurl/',
  myProposals: '/cabinet/my_lots/',
  newPassword: '/new-password/:token/',
  notFound: '/404/',
  notifications: '/notifications/',
  offerPage: '/proposals/offer/',
  offerPageMyProposals: '/proposals/offer/',
  privacyPolicy: '/privacy-policy/',
  cookiePolicy: '/cookie-policy/',
  proposals: '/proposals/',
  publicAgreement: '/public-agreement/',
  quickAuth: '/quick-login/:otp_token/',
  registration: {
    root: '/registration/',
    company: '/registration/company/',
  },
  reviews: {
    root: '/reviews/',
    create: '/reviews/create/',
  },
  businessCard: {
    root: '/business-card/',
    create: '/business-card/create/',
    show: '/business-card/:id/',
  },
  previewPage: `/proposals/:type(${LotType.request}|${LotType.offer})/:id(\\d+)/`,
  requestPage: '/proposals/request/',
  requestPageMyProposals: '/proposals/request/',
  resetPassword: '/reset-password/',
  settings: {
    root: '/settings/',
    company: '/settings/company/',
    subscription: '/settings/subscription/',
    user: '/settings/user/',
    users: '/settings/users/',
    invites: '/settings/invites/',
    addresses: {
      root: '/settings/addresses/',
      archive: '/settings/addresses/archive/',
      show: '/settings/addresses/:id(\\d+)/',
      edit: '/settings/addresses/:id(\\d+)/edit/',
      clone: '/settings/addresses/:id(\\d+)/clone/',
      create: '/settings/addresses/create/',
    },
  },
  signIn: '/sign-in/',
  signUp: '/sign-up/',
  auth: {
    signUp: {
      business_card: '/auth/sign-up/business-card/:uuid/',
    },
  },
  landingPages: '/lp/:cultureId/:countryId/',
} as const;


export type FlattenObjectKeys<
  T extends Record<string, unknown>,
  Value = T[keyof T]
> = Value extends string
  ? Value
  : Value extends Record<string, unknown>
    ? FlattenObjectKeys<Value>
    : never;


export const GROUP_ROUTES = {
  authLayout: [ROUTES.signIn, ROUTES.signUp, ROUTES.resetPassword, ROUTES.newPassword, ROUTES.auth.signUp.business_card],
  registrationLayout: [ROUTES.registration.root, ROUTES.registration.company],
};

export class BuilderLinkTo {
  static lot(lot: { id: number, type: string }): string {
    if (lot?.type === 'offer') return this.offer(lot);
    if (lot?.type === 'request') return this.request(lot);
    return ROUTES.market;
  }
  static offer(offer: { id: number }): string {
    if (!offer) return ROUTES.market;
    return `${ROUTES.offerPage}${offer.id}/`;
  }
  static request(request: { id: number }): string {
    if (!request) return ROUTES.market;
    return `${ROUTES.requestPage}${request.id}/`;
  }
  static trade(instance?: { id?: number }): string {
    return instance?.id
      ? `${ROUTES.chat}?tradeId=${instance.id}`
      : '';
  }
}
