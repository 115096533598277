import { ShareTypes } from 'modules/PreviewPage/components/ShareButton/interfaces';

export const SCOPE_SHARED_BUTTONS = 'ShareButton';

const encodeBaseUrl = encodeURIComponent(process.env.REACT_APP_SITE_URL || '');
const nbsp = '\u00A0';

export const uk = {
  types: {
    [ShareTypes.lot]: {
      name: 'Картка Заявки',
      info: 'Картка Заявки',
    },
    [ShareTypes.lots]: {
      name: 'Заявки',
      info: `$t(lot, {"count": {{count}} }), об\`ємом {{volume}}${nbsp}т`,
    },
    [ShareTypes.companyLots]: {
      name: 'Заявки компанії',
      info: 'Компанія "{{company}}"',
      link: 'Заявки компанії "{{company}}"',
      text: 'Заявки компанії "{{company}}"',
    },
    [ShareTypes.filteredCompanyLots]: {
      name: 'Отфільтровані заявки компанії',
      info: 'Компанія "{{company}}"',
      link: 'Отфільтровані заявки компанії "{{company}}"',
      text: 'Заявки компанії "{{company}}"',
    },
    [ShareTypes.similar]: {
      name: 'Подібні Заявки',
      link: 'Подібні заявки',
    },
    [ShareTypes.opposite]: {
      name: 'Зустрічні Заявки',
      link: 'Зустрічні заявки',
    },
    [ShareTypes.company]: {
      name: '{{name}}',
      info: 'Компанія "{{name}}"',
      text: 'Сторінка компанії "{{name}}"',
      disabled_text: 'Компанія має закритий профайл',
    },
  },
  title: 'Поділіться посиланнями',
  sharing_section: 'Поділитися в...',
  buttons: {
    qrCode: 'QR код',
    copy: 'Копіювати посилання',
    email: 'Пошта',
    viber: 'Viber',
    telegram: 'Telegram',
    whatsapp: 'WhatsApp',
    facebook: 'Facebook',
    twitter: 'Twitter',
    device: 'Системні інструменти',
  },
  email_body: `Торгова платформа Гленділ (${encodeBaseUrl})%0A{{encodeText}}%0AПосилання: {{encodeUrl}}`,
  button_tooltips: {
    qrCode: `Показати $t(${SCOPE_SHARED_BUTTONS}$buttons$qrCode)`,
    copy: 'Копіювати посилання',
    email: 'Поділитися через електронну пошту',
    viber: `Поділитися за допомогою $t(${SCOPE_SHARED_BUTTONS}$buttons$viber)`,
    telegram: `Поділитися за допомогою $t(${SCOPE_SHARED_BUTTONS}$buttons$telegram)`,
    whatsapp: `$t(${SCOPE_SHARED_BUTTONS}$buttons$whatsapp) має бути встановлений`,
    facebook: `Поділитися за допомогою $t(${SCOPE_SHARED_BUTTONS}$buttons$facebook)`,
    twitter: `Поділитися за допомогою $t(${SCOPE_SHARED_BUTTONS}$buttons$twitter)`,
    device: 'Поділитися за допомогою системних інструментів',
  },
};

export const en: typeof uk = {
  types: {
    [ShareTypes.lot]: {
      name: 'Lot card',
      info: 'Lot card',
    },
    [ShareTypes.lots]: {
      name: 'Lots',
      info: `$t(lot, {"count": {{count}} }), volume {{volume}}${nbsp}t`,
    },
    [ShareTypes.companyLots]: {
      name: 'Company requests',
      info: 'Company "{{company}}"',
      link: 'Lots of the company "{{company}}"',
      text: 'Lots of the company "{{company}}"',
    },
    [ShareTypes.filteredCompanyLots]: {
      name: 'Filtered company requests',
      info: 'Company "{{company}}"',
      link: 'Filtered lots of the company "{{company}}"',
      text: 'Lots of the company "{{company}}"',
    },
    [ShareTypes.similar]: {
      name: 'Similar Lots',
      link: 'Similar lots',
    },
    [ShareTypes.opposite]: {
      name: 'Opposite Lots',
      link: 'Opposit lots',
    },
    [ShareTypes.company]: {
      name: '{{name}}',
      info: 'Company "{{name}}"',
      text: 'Company page "{{name}}"',
      disabled_text: 'The company has a closed profile',
    },
  },
  title: 'Share links',
  sharing_section: 'Share...',
  buttons: {
    qrCode: 'QR code',
    copy: 'Copy link',
    email: 'Email',
    viber: 'Viber',
    telegram: 'Telegram',
    whatsapp: 'WhatsApp',
    facebook: 'Facebook',
    twitter: 'Twitter',
    device: 'Your System tools',
  },
  email_body: `Trading platform Glendeal (${encodeBaseUrl})%0A{{encodeText}}%0ALink: {{encodeUrl}}`,
  button_tooltips: {
    qrCode: `Show $t(${SCOPE_SHARED_BUTTONS}$buttons$qrCode)`,
    copy: 'Copy link',
    email: `Share on $t(${SCOPE_SHARED_BUTTONS}$buttons$email)`,
    viber: `Share on $t(${SCOPE_SHARED_BUTTONS}$buttons$viber)`,
    telegram: `Share on $t(${SCOPE_SHARED_BUTTONS}$buttons$telegram)`,
    whatsapp: `$t(${SCOPE_SHARED_BUTTONS}$buttons$whatsapp) must be installed`,
    facebook: `Share on $t(${SCOPE_SHARED_BUTTONS}$buttons$facebook)`,
    twitter: `Share on $t(${SCOPE_SHARED_BUTTONS}$buttons$twitter)`,
    device: 'Share using system tools',
  },
};

export const ru: typeof uk = {
  types: {
    [ShareTypes.lot]: {
      name: 'Карточка Заявки',
      info: 'Карточка Заявки',
    },
    [ShareTypes.lots]: {
      name: 'Заявки',
      info: `$t(lot, {"count": {{count}} }), объём {{volume}}${nbsp}т`,
    },
    [ShareTypes.companyLots]: {
      name: 'Заявки компании',
      info: 'Компания "{{company}}"',
      link: 'Заявки компании "{{company}}"',
      text: 'Заявки компании "{{company}}"',
    },
    [ShareTypes.filteredCompanyLots]: {
      name: 'Отфильтрованніе заявки компании',
      info: 'Компания "{{company}}"',
      link: 'Отфильтрованніе заявки компании "{{company}}"',
      text: 'Заявки компании "{{company}}"',
    },
    [ShareTypes.similar]: {
      name: 'Похожие Заявки',
      link: 'Подобные заявки',
    },
    [ShareTypes.opposite]: {
      name: 'Встречные Заявки',
      link: 'Встречные заявки',
    },
    [ShareTypes.company]: {
      name: '{{name}}',
      info: 'Компания "{{name}}"',
      text: 'Страница компании "{{name}}"',
      disabled_text: 'Компания имеет закрытый профайл',
    },
  },
  title: 'Поделиться ссылками',
  sharing_section: 'Поделиться в...',
  buttons: {
    qrCode: 'QR код',
    copy: 'Копировать ссылку',
    email: 'Почта',
    viber: 'Viber',
    telegram: 'Telegram',
    whatsapp: 'WhatsApp',
    facebook: 'Facebook',
    twitter: 'Twitter',
    device: 'Системные инструменты',
  },
  email_body: `Торговая платформа Глендил (${encodeBaseUrl})%0A{{encodeText}}%0AСсылка: {{encodeUrl}}`,
  button_tooltips: {
    qrCode: `Показать $t(${SCOPE_SHARED_BUTTONS}$buttons$qrCode)`,
    copy: 'Копировать ссылку',
    email: 'Поделиться через электронную почту',
    viber: `Поделиться с помощью $t(${SCOPE_SHARED_BUTTONS}$buttons$viber)`,
    telegram: `Поделиться с помощью $t(${SCOPE_SHARED_BUTTONS}$buttons$telegram)`,
    whatsapp: `$t(${SCOPE_SHARED_BUTTONS}$buttons$whatsapp) должен быть установлен`,
    facebook: `Поделиться с помощью $t(${SCOPE_SHARED_BUTTONS}$buttons$facebook)`,
    twitter: `Поделиться с помощью $t(${SCOPE_SHARED_BUTTONS}$buttons$twitter)`,
    device: 'Поделиться с помощью системных инструментов',
  },
};


export const bg: typeof uk = {
  types: {
    [ShareTypes.lot]: {
      name: 'Картичка на Заявката',
      info: 'Картичка на Заявката',
    },
    [ShareTypes.lots]: {
      name: 'Заявки',
      info: `$t(lot, {"count": {{count}} }), обем {{volume}}${nbsp}т`,
    },
    [ShareTypes.companyLots]: {
      name: 'Заявки на компанията',
      info: 'Компания "{{company}}"',
      link: 'Заявки на компанията "{{company}}"',
      text: 'Заявки на компанията "{{company}}"',
    },
    [ShareTypes.filteredCompanyLots]: {
      name: 'Филтрирани заявки на компанията',
      info: 'Компания "{{company}}"',
      link: 'Филтрирани заявки на компанията "{{company}}"',
      text: 'Заявки на компанията "{{company}}"',
    },
    [ShareTypes.similar]: {
      name: 'Подобни Заявки',
      link: 'Подобни заявки',
    },
    [ShareTypes.opposite]: {
      name: 'Контрарни Заявки',
      link: 'Контрарни заявки',
    },
    [ShareTypes.company]: {
      name: '{{name}}',
      info: 'Компания "{{name}}"',
      text: 'Фирмена страница "{{name}}"',
      disabled_text: 'Компанията има закрит профил',
    },
  },
  title: 'Споделяне на връзки',
  sharing_section: 'Споделяне в...',
  buttons: {
    qrCode: 'QR код',
    copy: 'Копиране на връзката',
    email: 'Електронна поща',
    viber: 'Viber',
    telegram: 'Telegram',
    whatsapp: 'WhatsApp',
    facebook: 'Facebook',
    twitter: 'Twitter',
    device: 'Системни инструменти',
  },
  email_body: `Търговска платформа Glendeal (${encodeBaseUrl})%0A{{encodeText}}%0AВръзка: {{encodeUrl}}`,
  button_tooltips: {
    qrCode: `Показване на $t(${SCOPE_SHARED_BUTTONS}$buttons$qrCode)`,
    copy: 'Копиране на връзката',
    email: 'Споделяне чрез електронна поща',
    viber: `Споделяне чрез $t(${SCOPE_SHARED_BUTTONS}$buttons$viber)`,
    telegram: `Споделяне чрез $t(${SCOPE_SHARED_BUTTONS}$buttons$telegram)`,
    whatsapp: `$t(${SCOPE_SHARED_BUTTONS}$buttons$whatsapp) трябва да бъде инсталиран`,
    facebook: `Споделяне чрез $t(${SCOPE_SHARED_BUTTONS}$buttons$facebook)`,
    twitter: `Споделяне чрез $t(${SCOPE_SHARED_BUTTONS}$buttons$twitter)`,
    device: 'Споделяне чрез системните инструменти',
  },
};
